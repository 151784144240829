import { Helmet } from "react-helmet"
import { EmphaticLinkItem } from "../components/linkItem"
import Layout from "../components/layout"
import React from "react"

const ExternalLink: React.ElementType<React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>> = EmphaticLinkItem.withComponent("a")

export default function Home() {
  return (
    <Layout>
      <Helmet>
        <title>Ryan Fitzgerald</title>
      </Helmet>
      <h1>Ryan Fitzgerald</h1>
      <ExternalLink href="https://github.com/rf-">GitHub</ExternalLink>
      <ExternalLink href="https://twitter.com/rrff__">Twitter</ExternalLink>
      <ExternalLink href="https://m.rynftz.gr/@ryan">Mastodon</ExternalLink>
      <ExternalLink href="https://spencerowen.bandcamp.com/album/presentation">
        Music
      </ExternalLink>
      <EmphaticLinkItem to="/puzzles">Puzzles</EmphaticLinkItem>
      <ExternalLink href="https://ducktiles.com">Ducktiles</ExternalLink>
      <ExternalLink href="https://ingrid.cx">Ingrid</ExternalLink>
      <ExternalLink href="mailto:rwfitzge@gmail.com">Email</ExternalLink>
    </Layout>
  )
}
