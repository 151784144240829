import { Link } from "gatsby"
import styled from "@emotion/styled"

export const LinkItemA = styled.a``

export const LinkItem = styled(Link)`
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 24px;
  margin-bottom: 8px;
  text-decoration: none;
`

export const EmphaticLinkItem = styled(LinkItem)`
  font-weight: 700;
`

export const SubtleLinkItem = styled(LinkItem)`
  opacity: 0.6;
`
